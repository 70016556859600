import { b2cCustomPolicyName, msalInstance } from '@/bootstrap/msal'
import clearPersistStorage from '@/bootstrap/redux/clearPersistStorage'
import { ICommandBarItemProps, DirectionalHint } from '@fluentui/react'
import MessagesList from '../components/NotificationList'
import { MSAL_CLIENT_ID, MSAL_REDIRECT_URL } from '@/environment/environment.constants'
import { IUserProfileModel } from '@/shared/types/swagger'

// items cannot contain NotificationList directly as it contains a hook and causes an error. This wraps around NotificationList to safely call the hook.
const MessagesListWrapper = () => {
  return (
    <MessagesList />
  )
}

const getItems = (notCAEmployee: boolean, profile?: IUserProfileModel) => {
  return {
    messages: {
      key: 'messages',
      subMenuProps: {
        isBeakVisible: true,
        beakWidth: 16,
        directionalHint: DirectionalHint.bottomRightEdge,
        items: [
          { 
            key: 'messagesList',
            onRender: MessagesListWrapper,
            data: { testing: 'test' }
          }
        ]
      }
    } as ICommandBarItemProps,
    support: {
      iconProps: { iconName: 'info' },
      key: 'support',
      href: '/support'
    } as ICommandBarItemProps,
    useractions: {
      key: 'useractions',
      subMenuProps: {
        items: [
          {
            iconProps: { iconName: 'Contact' },
            href: '/profile',
            key: 'myaccount',
          },
          notCAEmployee && (profile && profile.hasMultipleAccounts) && {
            iconProps: { iconName: 'Switch' },
            key: 'SwitchAccount',
            onClick: async () => { 
              await clearPersistStorage()
              msalInstance.loginRedirect({
                scopes: [],
                prompt: 'none',
                extraQueryParameters: {
                  'p': b2cCustomPolicyName,
                  'switchAccount':'Yes',
                  'client_id': MSAL_CLIENT_ID,
                  'redirect_uri': MSAL_REDIRECT_URL,
                  'scope': 'openid',
                }
              })
            }
          },
          {
            iconProps: { iconName: 'SignOut' },
            key: 'signout',
            onClick: async () => { 
              await clearPersistStorage()
              msalInstance.logoutRedirect() 
            }
          }
        ].filter(Boolean),
      }
    } as ICommandBarItemProps,
    myaccount: {
      key: 'myaccount',
      href: '/profile'
    } as ICommandBarItemProps
  }
}

type NavItemsKeys = keyof ReturnType<typeof getItems>
export type NavItems = Record<NavItemsKeys, ICommandBarItemProps>

export default getItems