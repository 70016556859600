import { useEffect, useMemo, useState } from 'react'
import { emmaApiService } from '.'
import {
  CURRENCY_COLUMN_CELL_CLASS,
  DATE_COLUMN_CELL_CLASS,
  DATE_COLUMN_FORMAT,
  DATE_TIME_COLUMN_CELL_CLASS,
  DATE_TIME_COLUMN_FORMAT,
  FONT, HTML_CELL_CLASS,
  PERCENTAGE_COLUMN_CELL_CLASS,
} from '@/lib/common/components/grid/GridExport'
import {
  AdaptDateAndTimeFieldsMapper,
  DEFAULT_DATE_FORMAT,
  DEFAULT_FULL_DATE_FORMAT,
  formatDate,
  getDifferenceInMonths,
  parseFormattedDate,
} from '@/lib/common/services/date/DateService'
import UtilService from '@/lib/common/services/util/UtilService'
import { ColDef, ExcelStyle, GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import { EXPORT_CELL_CLASS } from '@/screens/ClientPortfolio/ClientPortfolioPerformance/grid/cellFramework/PerformanceGridAutoGroupCellRenderer'

export const PERFORMANCE_MEDIAN_KEYS = ['CA Percentile Ranking', 'Universe Size', 'MgrMedian', 'MgrMedianPctRank', 'MgrMedianSize']
export const PERFORMANCE_MEDIAN_NODE_TYPES = ['PeerMedian','PeerMedianSize','PeerMedianPctRank','MgrMedianSize','MgrMedianPctRank','MgrMedian']
export const RAW_DATA_MONTHLY_PREFIX = 'monthly-return-'
export const RAW_DATA_QUARTERLY_PREFIX = 'quarterly-return-'
export const RAW_DATA_ANNUAL_PREFIX = 'annual-return-'
export const SLEEVE_DISPLAY_TYPE = 'Sleeve'
export const INVESTMENT_DISPLAY_TYPE = 'Investment'
export const POLICY_BENCHMARK_NODE_TYPE = 'POLICY_BENCHMARK'
export const BENCHMARK_DISPLAY_TYPE = 'Benchmark'
export const VALUE_ADDED_DISPLAY_TYPE = 'ValueAdded'
export const NET_OF_FEE_DISPLAY_TYPE = 'NetOfCAFees'

const TIME_PERIOD_PORTFOLIO = {
  "one month": '(1mo)',
  "three month": '(3mo)',
  "six month": '(6mo)',
  "nine month": '(9mo)',
  "one year": '(1yr)',
  "two years": '(2yr)',
  "three years": '(3yr)',
  "four years": '(4yr)',
  "five years": '(5yr)',
  "qtd": '(qtd)',
}

export interface HasPortfolioName {
  investmentPoolName?: string,
  shortName?: string,
  longName?: string,
}

export interface ICurrency {
  isoCode: string,
  name: string,
  symbol: string,
}

export interface IPortfolioClient {
  name?: string,
  currency?: string,
  currencySymbol?: string,
  marketValue?: number,
  changePercent?: number,
  mtdReturn?: number,
  qtdReturn?: number,
  ytdReturn?: number,
  itdReturn?: number,
}

export interface IPortfolio extends HasPortfolioName {
  cambridgeId?: string,
  totalAssetCompositeId?: number,
  aacsMemberId?: number,
  legacyClientId?: number,
  legacyId?: number,
  clientLongName?: string,
  clientShortName?: string,
  portfolioId?: string,
  clientId?: string,
  isoCurrency?: string,
  sourceName?: string,
  inceptionDate?: Date,
  liquidationDate?: Date,
  modifiedAt?: string,
  createdAt?: string,
  sourceTime?: string,
  active?: boolean,
  deleted?: boolean,
  isTacPortfolio?: boolean,
}

export interface IPortfolioVermilionSetting {
  entityId: number,
  entityName: string,
  fieldId: number,
  dataType: string,
  displayFormat: string,
  fieldName: string,
  value: string,
  rowId: number,
  keyValue: string,
  settingValue: string,
  references?: {
    value: string,
    entityId: number,
    entityFieldId: number,
    entityName: string,
  }[],
}

export interface IPortfolioSummaryWidgetFieldSetting {
  display: boolean,
  objectProperty?: string,
  mobileColumnName?: string[],
  desktopColumnName?: string[],
}

export interface IPortfolioAllocationSummaryFieldSetting extends IPortfolioSummaryWidgetFieldSetting {
}

export interface IPortfolioAllocationSummaryWidgetSettings {
  displayLevel1?: boolean,
  displayLevel2?: boolean,
  displayLevel3?: boolean,
  displayLevel4?: boolean,
  displayLevel5?: boolean,
  marketValueInMillions?: IPortfolioAllocationSummaryFieldSetting,
  allocation?: IPortfolioAllocationSummaryFieldSetting,
  marketValue?: IPortfolioAllocationSummaryFieldSetting,
  interimTargetVariance?: IPortfolioAllocationSummaryFieldSetting,
  longTermTargetVariance?: IPortfolioAllocationSummaryFieldSetting,
  target?: IPortfolioAllocationSummaryFieldSetting,
  targetAmount?: IPortfolioAllocationSummaryFieldSetting,
  targetRange?: IPortfolioAllocationSummaryFieldSetting,
}

export interface IPortfolioPerformanceSummaryFieldSetting extends IPortfolioSummaryWidgetFieldSetting {
}

export interface IPortfolioPerformanceSummaryWidgetSettings {
  displayPerformanceSumaryMtd?: boolean,
  displayPerformanceSumaryQtd?: boolean,
  displayPerformanceSumaryFiscalYtd?: boolean,
  displayPerformanceSumaryYtd?: boolean,
  displayPerformanceSumary1Year?: boolean,
  displayPerformanceSumary2Year?: boolean,
  displayPerformanceSumary3Year?: boolean,
  displayPerformanceSumary4Year?: boolean,
  displayPerformanceSumary5Year?: boolean,
  displayPerformanceSumary6Year?: boolean,
  displayPerformanceSumary7Year?: boolean,
  displayPerformanceSumary8Year?: boolean,
  displayPerformanceSumary9Year?: boolean,
  displayPerformanceSumary10Year?: boolean,
  displayPerformanceSumary11Year?: boolean,
  displayPerformanceSumary12Year?: boolean,
  displayPerformanceSumary13Year?: boolean,
  displayPerformanceSumary14Year?: boolean,
  displayPerformanceSumary15Year?: boolean,
  displayPerformanceSumaryCustomPeriod?: boolean,
  displayPerformanceSumarySinceFirstFullQuarter?: boolean,
  displayPerformanceSumarySinceFirstFullMonth?: boolean,
  displayPerformanceSumaryAnnualizedSinceInception?: boolean,
  displayPerformanceSumaryCumulative?: boolean,
  displayPerformanceSummaryNetOfFees?: boolean,
  displayPerformanceSumaryLevel1?: boolean,
  displayPerformanceSumaryLevel2?: boolean,
  displayPerformanceSumaryLevel3?: boolean,
  displayPerformanceSumaryLevel4?: boolean,
  displayPerformanceSumaryLevel5?: boolean,
}

export interface IPortfolioPerformanceTabFieldSetting extends IPortfolioSummaryWidgetFieldSetting {
}

export interface IPortfolioPerformanceTabWidgetSettings {
  displayPerformanceSleeves?: boolean,
  displayPerformanceInvestments?: boolean,
  displayPerformanceBenchmarks?: boolean,
  displayPerformanceValueAdd?: boolean,
  displayPerformanceInvestmentsLocalCurrency?: boolean,
  displayPerformanceInceptionDate?: boolean,
  displayPerformanceMarketValue?: boolean,
  displayPerformanceMarketValueMillions?: boolean,
  displayPerformanceNetCommitedCapital?: boolean,
  displayPerformanceNetCommitedCapitalMillions?: boolean,
  displayPerformanceMtd?: boolean,
  displayPerformanceQtd?: boolean,
  displayPerformanceFiscalYtd?: boolean,
  displayPerformanceCalendarYtd?: boolean,
  displayPerformance1Yr?: boolean,
  displayPerformance2Yr?: boolean,
  displayPerformance3Yr?: boolean,
  displayPerformance4Yr?: boolean,
  displayPerformance5Yr?: boolean,
  displayPerformance6Yr?: boolean,
  displayPerformance7Yr?: boolean,
  displayPerformance8Yr?: boolean,
  displayPerformance9Yr?: boolean,
  displayPerformance10Yr?: boolean,
  displayPerformance11Yr?: boolean,
  displayPerformance12Yr?: boolean,
  displayPerformance13Yr?: boolean,
  displayPerformance14Yr?: boolean,
  displayPerformance15Yr?: boolean,
  displayPerformanceSinceFirstMonth?: boolean,
  displayPerformanceSinceFirstQuarter?: boolean,
  displayPerformanceCustomPeriod?: boolean,
  displayPerformanceAnnualizedSinceInception?: boolean,
  displayPerformanceCumulativeSinceInception?: boolean,
}

export interface IPortfolioFiscalYearEndSetting {
  month?: string,
  shortMonth?: string,
}

export interface IPortfolioSettings {
  fiscalYearEnd?: IPortfolioFiscalYearEndSetting,
  currency?: ICurrency,
  investmentPoolId: string,
  inceptionDate: Date,
  investmentPoolName: string,
  dateFormat: string,
  fullDateFormat: string,
  headerDateFormat: string,
  allocationSummary?: IPortfolioAllocationSummaryWidgetSettings,
  performanceSummary?: IPortfolioPerformanceSummaryWidgetSettings,
  performanceTab?: IPortfolioPerformanceTabWidgetSettings,
}

export interface IPortfolioReturnsSummary {
  name?: string,
  currency: string,
  currencySymbol: string,
  marketValue: number,
  changePercent: number,
  mtdReturn: number,
  qtdReturn: number,
  ytdReturn: number,
  itdReturn: number,
  trailingPeriodName: string,
  trailingPeriodStartDate: string,
  trailingPeriodEndDate: string,
}

export interface IPortfolioSummary extends HasPortfolioName {
  isError?: boolean,
  isoCurrency?: string,
  portfolioId?: string,
  portfolioSummary?: IPortfolioReturnsSummary,
}

export interface IAllocation {
  id: number,
  Alloc: number,
  Color: string,
  ColorScheme: string,
  CurrencyISO: string,
  CurrencySymbol: string,
  DataStatus: string,
  DisplayPLIType: string,
  FootNoteIdx: string,
  IsManaged: boolean,
  IsPortfolioHedge: boolean,
  Liquidation: string,
  Lvl: number,
  MV: number,
  MVF: number,
  Name: string,
  NetCommitCap: number,
  NetCommitCapM: number,
  PLISk: number,
  ParentPLISk: number,
  TgtAllocLT: number,
  TgtAllocST: number,
  TgtRng: number,
  TgtRngFrom: number,
  TgtRngTo: number,
  TgtVarAmt: number,
  TgtVarAmtST: number,
  TgtVarPct: number,
  TgtVarPctST: number,
  parentId: number,
  StatusAggregate: string,
  footNoteDataFlags?: number[],
}

export interface IMarketValue {
  id: number,
  ChangeMV: number,
  CurrencyISO: string,
  CurrencySymbol: string,
  CurrMv: number,
  ChangeMVPct: number,
  NetCashFlows: number,
  Period: string,
  ProfLoss: number,
  StartMv: number,
  StartPeriod: string,
  EndPeriod: string,
  ChangePct?: number,
  StatusAggregate?: string,
  footNoteDataFlags?: number[],
}

export interface ITrade {
  amnt: number,
  currencyISO: string,
  date: Date,
  factTransDailyId: number,
  investmentPoolName: string,
  name: string,
  nkTransId: string,
  type: string,
  portfolioId?: string,
  portfolioName?: string,
  currency?: ICurrency,
}

export interface IPerformanceData {
  id: number,
  CurrencyISO?: string,
  CurrencySk?: number,
  CurrencySymbol?: string,
  DataStatus?: string,
  DisplayPLIType?: string,
  FeesTypeResult?: string,
  FootNoteIdx?: string,
  GroupPLISk?: number,
  InceptDate?: string,
  IsHidePerformance?: boolean,
  IsManaged?: boolean,
  IsPortfolioHedge?: boolean,
  IsPortfolioNetOfFees?: boolean,
  IsPrivateInvestment?: boolean,
  Lvl?: number,
  MV?: number,
  MVF?: number,
  Name?: string,
  NetCommitCap?: number,
  NetCommitCapM?: number,
  NodeType?: string,
  PLISk?: number,
  ParentPLISk?: number,
  SubGroupPLISk?: number,
  TwrCumItd?: number,
  TwrCust?: number,
  TwrCytd?: number,
  TwrFytdApr?: number,
  TwrFytdAug?: number,
  TwrFytdDec?: number,
  TwrFytdFeb?: number,
  TwrFytdJan?: number,
  TwrFytdJul?: number,
  TwrFytdJun?: number,
  TwrFytdMar?: number,
  TwrFytdMay?: number,
  TwrFytdNov?: number,
  TwrFytdOct?: number,
  TwrFytdSep?: number,
  TwrItd?: number,
  TwrMtd?: number,
  TwrQtd?: number,
  TwrS1FM?: number,
  TwrS1FQ?: number,
  TwrTr10Y?: number,
  TwrTr11Y?: number,
  TwrTr12Y?: number,
  TwrTr13Y?: number,
  TwrTr14Y?: number,
  TwrTr15Y?: number,
  TwrTr1Y?: number,
  TwrTr2Y?: number,
  TwrTr3Y?: number,
  TwrTr4Y?: number,
  TwrTr5Y?: number,
  TwrTr6Y?: number,
  TwrTr7Y?: number,
  TwrTr8Y?: number,
  TwrTr9Y?: number,
  StatusAggregate?: string,
  footNoteDataFlags?: number[],
}

export interface PeriodReturn {
  Date: string,
  Twr: number,
}

export interface PerformanceRawDataBase {
  [key: string]: any,
}

export interface PerformanceRawData extends PerformanceRawDataBase {
  id: number,
  CurrencyISO: string,
  CurrencySk: number,
  CurrencySymbol: string,
  DataStatus: string,
  DisplayPLIType: string,
  FeesTypeResult: string,
  FootNoteIdx: string,
  GroupPLISk: number,
  InceptDate: string,
  Inception: string,
  AnalysisDate: string,
  IsHidePerformance: boolean,
  IsManaged: boolean,
  IsPortfolioHedge: boolean,
  IsPortfolioNetOfFees: boolean,
  IsPrivateInvestment: boolean,
  Liquidation: string,
  Lvl: number,
  MV: number,
  MVF: number,
  Name: string,
  NetCommitCap: number,
  NetCommitCapM: number,
  NodeType: string,
  PLISk: number,
  ParentPLISk: number,
  SubGroupPLISk: number,
  TwrCumItd: number,
  TwrCust: number,
  TwrCytd: number,
  TwrFytdJan: number,
  TwrFytdFeb: number,
  TwrFytdMar: number,
  TwrFytdApr: number,
  TwrFytdMay: number,
  TwrFytdJun: number,
  TwrFytdJul: number,
  TwrFytdAug: number,
  TwrFytdSep: number,
  TwrFytdOct: number,
  TwrFytdNov: number,
  TwrFytdDec: number,
  TwrItd: number,
  TwrMtd: number,
  TwrQtd: number,
  TwrS1FM: number,
  TwrS1FQ: number,
  TwrTr1Y: number,
  TwrTr2Y: number,
  TwrTr3Y: number,
  TwrTr4Y: number,
  TwrTr5Y: number,
  TwrTr6Y: number,
  TwrTr7Y: number,
  TwrTr8Y: number,
  TwrTr9Y: number,
  TwrTr10Y: number,
  TwrTr11Y: number,
  TwrTr12Y: number,
  TwrTr13Y: number,
  TwrTr14Y: number,
  TwrTr15Y: number,
  PLIType: string,
  NK_Composite_ID: number,
  NK_Series_Code: string,
  MonthlyReturns: PeriodReturn[],
  QuarterlyReturns: PeriodReturn[],
  AnnualReturns: PeriodReturn[],
  MgrMedianFinalQuarter: string,
  StatusAggregate: string,
  disabled?: boolean,
  noDataDisabled?: boolean,
  vermilionSettingDisabled?: boolean,
  footNoteDataFlags?: number[],
}

export interface PerformanceHierarchy {
  element: PerformanceRawData,
  children: PerformanceHierarchy[],
}

export interface AnalyticsCacheClearInput {
  accountId: string,
  deleteSettingsData?: boolean,
  deleteMarketValueData?: boolean,
  deleteAllocationData: boolean,
  deletePerformanceData: boolean,
  deleteTransactionData: boolean,
}

export interface GeneralDisclaimers {
  code: string;
  name?: string;
  value?: string;
  isActive: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface BenchmarkEndNotesResult {
  benchMarkEndNotesId: string;
  investmentPoolId?: number;
  startDate?: Date;
  endDate?: Date;
  fileName?: string;
  orientation?: string;
  isActive?: boolean;
  downloadContext?: string;
}

export interface EndNotesResult {
  order: number;
  footNoteSk?: string;
  content?: string;
}

export interface BenchmarkEndNotesResponse {
  benchmnarkEndNotes?: BenchmarkEndNotesResult[];
}

export interface EndNotesResponse {
  summary?: GeneralDisclaimers;
  endNotes?: EndNotesResult[];
}

export interface EndNoteMap {
  footNoteId?: string;
  pliskIds?: Set<number>;
}

export interface EndNotesMapResponse {
  endNotesOrder?: Map<number, EndNoteMap>;
}

export interface BenchmarkEndNotesWithDownload extends BenchmarkEndNotesResult {
  downloadBinary?: string;
}

export interface BenchmarkEndNotesWithDownloadResponse {
  benchmnarkEndNotes?: BenchmarkEndNotesWithDownload[];
}

export const PerformanceRawDataMapper = (record: any): PerformanceRawData => {
  const rawData = {
    ...record,
    ...AdaptDateAndTimeFieldsMapper<PerformanceRawData>('InceptDate', 'Liquidation', 'Inception', 'AnalysisDate')(record),
  } as PerformanceRawData;

  (rawData.MonthlyReturns || []).forEach(element => {
    rawData[`${RAW_DATA_MONTHLY_PREFIX}${element.Date}`] = (element.Twr ? parseFloat((element.Twr).toFixed(6)) * 100 : element.Twr);
  });

  (rawData.QuarterlyReturns || []).forEach((element) => {
    rawData[`${RAW_DATA_QUARTERLY_PREFIX}${element.Date}`] = (element.Twr ? parseFloat((element.Twr).toFixed(6)) * 100 : element.Twr);
  });

  (rawData.AnnualReturns || []).forEach((element) => {
    rawData[`${RAW_DATA_ANNUAL_PREFIX}${element.Date}`] = (element.Twr ? parseFloat((element.Twr).toFixed(6)) * 100 : element.Twr);
  });

  Object.keys(rawData).forEach(key => {
    if (key.match(/Twr/g) && rawData[key] && (rawData.DisplayPLIType).toLowerCase().indexOf('median') === -1) {
      rawData[key] = parseFloat((rawData[key]).toFixed(6));
    }
    else if (key.match(/Twr/g) && rawData[key] && (rawData.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && PERFORMANCE_MEDIAN_KEYS[1] !== rawData.Name && PERFORMANCE_MEDIAN_KEYS[0] !== rawData.Name) {
      rawData[key] = rawData[key] ? parseFloat((rawData[key] * 100).toFixed(2)) : null;
    }
    else if (key.match(/Twr/g) && rawData[key] && (rawData.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && PERFORMANCE_MEDIAN_KEYS[0] === rawData.Name) {
      rawData[key] = rawData[key] ? parseFloat((rawData[key]).toFixed(2)) * 100 : null;
    }
  });

  return rawData;
};

export const PerformanceRawDataListMapper = (records: PerformanceRawDataBase): PerformanceRawData[] => {
  return (records || []).map((record: PerformanceRawDataBase[]) => PerformanceRawDataMapper(record));
};

const potfolioService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['PortfolioClient', 'PortfolioSettings', 'Disclaimers', 'BenchmarkEndNotes', 'PortfolioEndNotes', 'PortfolioEndNotesOrder'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getDisclaimers: build.query<GeneralDisclaimers[], void>({
        query: () => ({ url: `/portfolio/disclaimers`, method: 'GET' }),
        providesTags: ['Disclaimers'],
      }),
      getBenchmarkEndNotes: build.query<BenchmarkEndNotesResponse, { portfolioId, asOfDate }>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/portfolio/${portfolioId}/benchmarkEndNotes/${asOfDate}`, method: 'GET' }),
        providesTags: ['BenchmarkEndNotes'],
      }),
      getBenchmarkEndNotesPDFDocument: build.mutation<string, { portfolioId, benchMarkEndNotesId }>({
        query: ({ portfolioId, benchMarkEndNotesId }) => ({
          url: `/portfolio/${portfolioId}/benchmarkEndNotes/${benchMarkEndNotesId}/pdf`,
          method: 'GET'
        }),
      }),
      getEndNotesOrder: build.query<EndNotesMapResponse, { portfolioId, asOfDate }>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/portfolio/${portfolioId}/endNotes/${asOfDate}/order`, method: 'GET' }),
        providesTags: ['PortfolioEndNotesOrder'],
      }),
      getEndNotes: build.query<EndNotesResponse, { portfolioId, asOfDate }>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/portfolio/${portfolioId}/endNotes/${asOfDate}`, method: 'GET' }),
        providesTags: ['PortfolioEndNotes'],
      }),
      getPortfolioById: build.query<IPortfolio, string>({
        query: (portfolioId) => ({ url: `/portfolio/${portfolioId}`, method: 'GET' }),
        transformResponse: (response: IPortfolio) => {
          return {
            ...response,
            ...AdaptDateAndTimeFieldsMapper<IPortfolio>('InceptionDate', 'liquidationDate')(response),
          }
        },
        providesTags: ['PortfolioClient'],
      }),
      getPortfolioSettingsById: build.query<IPortfolioSettings, string>({
        query: (portfolioId) => ({ url: `/portfolio/${portfolioId}/settings`, method: 'GET' }),
        transformResponse: (response: {
          currency?: ICurrency,
          result: {
            investmentPoolId: string,
            settings: IPortfolioVermilionSetting[]
          }[]
        }) => {
          const settingsMap = (response?.result?.[0]?.settings || []).reduce((acc, settingValue) => {
            acc[settingValue?.fieldName] = settingValue
            return acc
          }, {} as {
            [settingKey: string]: IPortfolioVermilionSetting,
          })

          // vermilion settings - generic settings
          const fiscalYearEnd = {
            month: settingsMap?.['Fiscal Year End']?.references?.find(r => r.entityName === 'Months')?.value || '',
            shortMonth: settingsMap?.['Fiscal Year End']?.references?.find(r => r.entityName === 'ShortMonths')?.value || '',
          }
          const currency = response?.currency || {
            name: settingsMap?.['Linked Currency']?.references?.find(r => r.entityName === 'Currency Code')?.value,
            isoCode: settingsMap?.['Linked Currency']?.references?.find(r => r.entityName === 'Currency Code')?.value,
            symbol: settingsMap?.['Linked Currency']?.references?.find(r => r.entityName === 'Symbol')?.value,
          }
          const investmentPoolName = settingsMap?.['Investment Pool Name']?.settingValue || ''
          const inceptionDate = parseFormattedDate(settingsMap?.['Inception Date']?.settingValue, 'dd MMMM yyyy HH:mm:ss')
          const customReportingDate = parseFormattedDate(settingsMap?.['Custom Reporting Date']?.settingValue, 'dd MMMM yyyy HH:mm:ss')
          const dateFormat = (settingsMap?.['Date Format']?.references?.find(r => r.entityName === 'Short Date')?.value || DEFAULT_DATE_FORMAT)
            ?.replace(/m/g, 'M')?.replace(/D/g, 'd')?.replace(/Y/g, 'y')
          const fullDateFormat = (settingsMap?.['Date Format']?.references?.find(r => r.entityName === 'Full Date')?.value || DEFAULT_FULL_DATE_FORMAT)
            ?.replace(/m/g, 'M')?.replace(/D/g, 'd')?.replace(/Y/g, 'y')
          const headerDateFormat = fullDateFormat?.replace('MMMM', 'MMM')

          // vermilion settings for allocation summary widget on portfolio home tab
          const displayMarketValueInMillions = UtilService.isTruthy(settingsMap?.['Show Market Value - Millions (PAS)']?.settingValue)
          const displayAllocation = UtilService.isTruthy(settingsMap?.['Show Allocation (PAS)']?.settingValue)
          const displayMarketValue = UtilService.isTruthy(settingsMap?.['Show Market Value (PAS)']?.settingValue)
          const displayInterimTargetVariance = UtilService.isTruthy(settingsMap?.['Show Interim Target Variance (PAS)']?.settingValue)
          const displayLongTermTargetVariance = UtilService.isTruthy(settingsMap?.['Show Long Term Target Variance (PAS)']?.settingValue)
          const displayTarget = UtilService.isTruthy(settingsMap?.['Show Target (PAS)']?.settingValue)
          const displayTargetAmount = UtilService.isTruthy(settingsMap?.['Show Target Amount (PAS)']?.settingValue)
          const displayTargetRange = UtilService.isTruthy(settingsMap?.['Show Target Range (PAS)']?.settingValue)
          const displayLevel1 = UtilService.isTruthy(settingsMap?.['Show Level 1 (PAS)']?.settingValue)
          const displayLevel2 = UtilService.isTruthy(settingsMap?.['Show Level 2 (PAS)']?.settingValue)
          const displayLevel3 = UtilService.isTruthy(settingsMap?.['Show Level 3 (PAS)']?.settingValue)
          const displayLevel4 = UtilService.isTruthy(settingsMap?.['Show Level 4 (PAS)']?.settingValue)
          const displayLevel5 = UtilService.isTruthy(settingsMap?.['Show Level 5 (PAS)']?.settingValue)
          const allocationSummary: IPortfolioAllocationSummaryWidgetSettings = {
            displayLevel1,
            displayLevel2,
            displayLevel3,
            displayLevel4,
            displayLevel5,
            marketValueInMillions: {
              display: displayMarketValueInMillions,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            allocation: {
              display: displayAllocation,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            marketValue: {
              display: displayMarketValue,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            interimTargetVariance: {
              display: displayInterimTargetVariance,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            longTermTargetVariance: {
              display: displayLongTermTargetVariance,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            target: {
              display: displayTarget,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            targetAmount: {
              display: displayTargetAmount,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
            targetRange: {
              display: displayTargetRange,
              objectProperty: '',
              desktopColumnName: [],
              mobileColumnName: [],
            },
          }

          // vermilion settings for performance summary widget on portfolio home tab
          const displayPerformanceSumaryMtd = UtilService.isTruthy(settingsMap?.['Show MTD Returns (PRC)']?.settingValue)
          const displayPerformanceSumaryQtd = UtilService.isTruthy(settingsMap?.['Show QTD Returns (PRC)']?.settingValue)
          const displayPerformanceSumaryFiscalYtd = UtilService.isTruthy(settingsMap?.['Show Fiscal YTD Returns (PRC)']?.settingValue)
          const displayPerformanceSumaryYtd = UtilService.isTruthy(settingsMap?.['Show YTD Returns (PRC)']?.settingValue)
          const displayPerformanceSumary1Year = UtilService.isTruthy(settingsMap?.['Show 1 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary2Year = UtilService.isTruthy(settingsMap?.['Show 2 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary3Year = UtilService.isTruthy(settingsMap?.['Show 3 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary4Year = UtilService.isTruthy(settingsMap?.['Show 4 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary5Year = UtilService.isTruthy(settingsMap?.['Show 5 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary6Year = UtilService.isTruthy(settingsMap?.['Show 6 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary7Year = UtilService.isTruthy(settingsMap?.['Show 7 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary8Year = UtilService.isTruthy(settingsMap?.['Show 8 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary9Year = UtilService.isTruthy(settingsMap?.['Show 9 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary10Year = UtilService.isTruthy(settingsMap?.['Show 10 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary11Year = UtilService.isTruthy(settingsMap?.['Show 11 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary12Year = UtilService.isTruthy(settingsMap?.['Show 12 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary13Year = UtilService.isTruthy(settingsMap?.['Show 13 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary14Year = UtilService.isTruthy(settingsMap?.['Show 14 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumary15Year = UtilService.isTruthy(settingsMap?.['Show 15 Year Returns (PRC)']?.settingValue)
          const displayPerformanceSumaryCustomPeriod = UtilService.isTruthy(settingsMap?.['Show Custom Period Returns (PRC)']?.settingValue)
          const displayPerformanceSumarySinceFirstFullQuarter = UtilService.isTruthy(settingsMap?.['Show Returns Since First Full Quarter (PRC)']?.settingValue)
          const displayPerformanceSumarySinceFirstFullMonth = UtilService.isTruthy(settingsMap?.['Show Returns Since First Full Month (PRC)']?.settingValue)
          const displayPerformanceSumaryAnnualizedSinceInception = UtilService.isTruthy(settingsMap?.['Show Annualized Since Inception Returns (PRC)']?.settingValue)
          const displayPerformanceSumaryCumulative = UtilService.isTruthy(settingsMap?.['Show Cumulative Since Inception Returns (PRC)']?.settingValue)
          const displayPerformanceSummaryNetOfFees = UtilService.isFalsy(settingsMap?.['Hide Net of Fees (PRC)']?.settingValue)
          const displayPerformanceSumaryLevel1 = UtilService.isTruthy(settingsMap?.['Show Level 1 (PRC)']?.settingValue)
          const displayPerformanceSumaryLevel2 = UtilService.isTruthy(settingsMap?.['Show Level 2 (PRC)']?.settingValue)
          const displayPerformanceSumaryLevel3 = UtilService.isTruthy(settingsMap?.['Show Level 3 (PRC)']?.settingValue)
          const displayPerformanceSumaryLevel4 = UtilService.isTruthy(settingsMap?.['Show Level 4 (PRC)']?.settingValue)
          const displayPerformanceSumaryLevel5 = UtilService.isTruthy(settingsMap?.['Show Level 5 (PRC)']?.settingValue)
          const performanceSummary: IPortfolioPerformanceSummaryWidgetSettings = {
            displayPerformanceSumaryMtd,
            displayPerformanceSumaryQtd,
            displayPerformanceSumaryFiscalYtd,
            displayPerformanceSumaryYtd,
            displayPerformanceSumary1Year,
            displayPerformanceSumary2Year,
            displayPerformanceSumary3Year,
            displayPerformanceSumary4Year,
            displayPerformanceSumary5Year,
            displayPerformanceSumary6Year,
            displayPerformanceSumary7Year,
            displayPerformanceSumary8Year,
            displayPerformanceSumary9Year,
            displayPerformanceSumary10Year,
            displayPerformanceSumary11Year,
            displayPerformanceSumary12Year,
            displayPerformanceSumary13Year,
            displayPerformanceSumary14Year,
            displayPerformanceSumary15Year,
            displayPerformanceSumaryCustomPeriod,
            displayPerformanceSumarySinceFirstFullQuarter,
            displayPerformanceSumarySinceFirstFullMonth,
            displayPerformanceSumaryAnnualizedSinceInception,
            displayPerformanceSumaryCumulative,
            displayPerformanceSummaryNetOfFees,
            displayPerformanceSumaryLevel1,
            displayPerformanceSumaryLevel2,
            displayPerformanceSumaryLevel3,
            displayPerformanceSumaryLevel4,
            displayPerformanceSumaryLevel5,
          }

          // vermilion settings for portfolio performance tab
          const displayPerformanceSleeves = UtilService.isTruthy(settingsMap?.['Show Sleeves (PT)']?.settingValue)
          const displayPerformanceInvestments = UtilService.isTruthy(settingsMap?.['Show Investments (PT)']?.settingValue)
          const displayPerformanceBenchmarks = UtilService.isTruthy(settingsMap?.['Show Benchmarks (PT)']?.settingValue)
          const displayPerformanceValueAdd = UtilService.isTruthy(settingsMap?.['Show Value Add (PT)']?.settingValue)
          const displayPerformanceInvestmentsLocalCurrency = UtilService.isTruthy(settingsMap?.['Show Investments (Local Currency) (PT)']?.settingValue)
          const displayPerformanceInceptionDate = UtilService.isTruthy(settingsMap?.['Show Inception Date (PT)']?.settingValue)
          const displayPerformanceMarketValue = UtilService.isTruthy(settingsMap?.['Show Market Value (PT)']?.settingValue)
          const displayPerformanceMarketValueMillions = UtilService.isTruthy(settingsMap?.['Show Market Value - Millions (PT)']?.settingValue)
          const displayPerformanceNetCommitedCapital = UtilService.isTruthy(settingsMap?.['Show Net Committed Capital (PT)']?.settingValue)
          const displayPerformanceNetCommitedCapitalMillions = UtilService.isTruthy(settingsMap?.['Show Net Committed Capital - Millions (PT)']?.settingValue)
          const displayPerformanceMtd = UtilService.isTruthy(settingsMap?.['Show Performance MTD (PT)']?.settingValue)
          const displayPerformanceQtd = UtilService.isTruthy(settingsMap?.['Show Performance QTD (PT)']?.settingValue)
          const displayPerformanceFiscalYtd = UtilService.isTruthy(settingsMap?.['Show Performance Fiscal YTD (PT)']?.settingValue)
          const displayPerformanceCalendarYtd = UtilService.isTruthy(settingsMap?.['Show Performance Calendar YTD (PT)']?.settingValue)
          const displayPerformance1Yr = UtilService.isTruthy(settingsMap?.['Show Performance 1 Yr (PT)']?.settingValue)
          const displayPerformance2Yr = UtilService.isTruthy(settingsMap?.['Show Performance 2 Yr (PT)']?.settingValue)
          const displayPerformance3Yr = UtilService.isTruthy(settingsMap?.['Show Performance 3 Yr (PT)']?.settingValue)
          const displayPerformance4Yr = UtilService.isTruthy(settingsMap?.['Show Performance 4 Yr (PT)']?.settingValue)
          const displayPerformance5Yr = UtilService.isTruthy(settingsMap?.['Show Performance 5 Yr (PT)']?.settingValue)
          const displayPerformance6Yr = UtilService.isTruthy(settingsMap?.['Show Performance 6 Yr (PT)']?.settingValue)
          const displayPerformance7Yr = UtilService.isTruthy(settingsMap?.['Show Performance 7 Yr (PT)']?.settingValue)
          const displayPerformance8Yr = UtilService.isTruthy(settingsMap?.['Show Performance 8 Yr (PT)']?.settingValue)
          const displayPerformance9Yr = UtilService.isTruthy(settingsMap?.['Show Performance 9 Yr (PT)']?.settingValue)
          const displayPerformance10Yr = UtilService.isTruthy(settingsMap?.['Show Performance 10 Yr (PT)']?.settingValue)
          const displayPerformance11Yr = UtilService.isTruthy(settingsMap?.['Show Performance 11 Yr (PT)']?.settingValue)
          const displayPerformance12Yr = UtilService.isTruthy(settingsMap?.['Show Performance 12 Yr (PT)']?.settingValue)
          const displayPerformance13Yr = UtilService.isTruthy(settingsMap?.['Show Performance 13 Yr (PT)']?.settingValue)
          const displayPerformance14Yr = UtilService.isTruthy(settingsMap?.['Show Performance 14 Yr (PT)']?.settingValue)
          const displayPerformance15Yr = UtilService.isTruthy(settingsMap?.['Show Performance 15 Yr (PT)']?.settingValue)
          const displayPerformanceSinceFirstMonth = UtilService.isTruthy(settingsMap?.['Show Performance Since First Month (PT)']?.settingValue)
          const displayPerformanceSinceFirstQuarter = UtilService.isTruthy(settingsMap?.['Show Performance Since First Quarter (PT)']?.settingValue)
          const displayPerformanceCustomPeriod = UtilService.isTruthy(settingsMap?.['Show Performance Custom Period (PT)']?.settingValue)
          const displayPerformanceAnnualizedSinceInception = UtilService.isTruthy(settingsMap?.['Show Performance Annualized Since Inception (PT)']?.settingValue)
          const displayPerformanceCumulativeSinceInception = UtilService.isTruthy(settingsMap?.['Show Performance Cumulative Since Inception (PT)']?.settingValue)
          const performanceTab: IPortfolioPerformanceTabWidgetSettings = {
            displayPerformanceSleeves,
            displayPerformanceInvestments,
            displayPerformanceBenchmarks,
            displayPerformanceValueAdd,
            displayPerformanceInvestmentsLocalCurrency,
            displayPerformanceInceptionDate,
            displayPerformanceMarketValue,
            displayPerformanceMarketValueMillions,
            displayPerformanceNetCommitedCapital,
            displayPerformanceNetCommitedCapitalMillions,
            displayPerformanceMtd,
            displayPerformanceQtd,
            displayPerformanceFiscalYtd,
            displayPerformanceCalendarYtd,
            displayPerformance1Yr,
            displayPerformance2Yr,
            displayPerformance3Yr,
            displayPerformance4Yr,
            displayPerformance5Yr,
            displayPerformance6Yr,
            displayPerformance7Yr,
            displayPerformance8Yr,
            displayPerformance9Yr,
            displayPerformance10Yr,
            displayPerformance11Yr,
            displayPerformance12Yr,
            displayPerformance13Yr,
            displayPerformance14Yr,
            displayPerformance15Yr,
            displayPerformanceSinceFirstMonth,
            displayPerformanceSinceFirstQuarter,
            displayPerformanceCustomPeriod,
            displayPerformanceAnnualizedSinceInception,
            displayPerformanceCumulativeSinceInception,
          }

          return {
            fiscalYearEnd,
            investmentPoolName,
            dateFormat,
            fullDateFormat,
            headerDateFormat,
            currency,
            investmentPoolId: response?.result?.[0]?.investmentPoolId,
            inceptionDate: customReportingDate || inceptionDate,
            allocationSummary,
            performanceSummary,
            performanceTab,
          } as IPortfolioSettings
        },
        providesTags: ['PortfolioSettings'],
      }),
    }),
  })

const transactionService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['TransactionAll', 'RecentTransaction', 'RecentTransactionByAccount'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllTransaction: build.query<ITrade[], any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/transaction/getAll?portfolioId=${portfolioId}&asOfDate=${asOfDate}`, method: 'GET' }),
        providesTags: ['TransactionAll'],
        transformResponse: (response: {
          transactions: ITrade[],
          currency: ICurrency,
        }) => {
          return (response?.transactions || []).map(t => ({
            ...t,
            ...AdaptDateAndTimeFieldsMapper<ITrade>('date')(t),
            currency: response?.currency,
          }))
        },
      }),
      getRecentTransactionByAccount: build.query<{
        portfolio: IPortfolio,
        transactions: ITrade[],
        currency: ICurrency,
      }[], any>({
        query: ({ accountId, asOfDate, listLimit }) => ({ url: `/transaction/recentTransactionsByAccount?accountId=${accountId}&asOfDate=${asOfDate}&transactionListLimit=${listLimit}`, method: 'GET' }),
        providesTags: ['RecentTransactionByAccount'],
      }),
      getRecentTransaction: build.query<ITrade[], any>({
        query: ({ portfolioId, asOfDate, listLimit }) => ({ url: `/transaction/recentTransactions?portfolioId=${portfolioId}&asOfDate=${asOfDate}&transactionListLimit=${listLimit}`, method: 'GET' }),
        providesTags: ['RecentTransaction'],
        transformResponse: (response: {
          transactions: ITrade[],
          currency: ICurrency,
        }) => {
          return (response?.transactions || []).map(t => ({
            ...t,
            ...AdaptDateAndTimeFieldsMapper<ITrade>('date')(t),
            currency: response?.currency,
          }))
        },
      })
    }),
  })

const statsService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['StatsTransaction', 'StatsPerformance', 'StatsTopFiveHoldings', 'StatsSummary', 'StatsMarketValue', 'StatsAllocation', 'SleevesFilter'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getStatsTransaction: build.query<any, any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/stats/transaction?portfolioId=${portfolioId}&baseDate=${asOfDate}`, method: `GET` }),
        providesTags: ['StatsTransaction'],
      }),
      getStatsTopFive: build.query<{ result: IPerformanceData[] }, any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/stats/top5?portfolioId=${portfolioId}&asOfDate=${asOfDate}`, method: 'GET' }),
        providesTags: ['StatsTopFiveHoldings'],
      }),
      getStatsSummary: build.query<IPortfolioSummary[], any>({
        query: ({ accountId, asOfDate }) => ({ url: `/stats/summary?accountId=${accountId}&asOfDate=${asOfDate}`, method: 'GET' }),
        providesTags: ['StatsSummary'],
        transformResponse: (response: IPortfolioSummary[]) => {
          return response?.map(p => {
            try {
              if (p && p.portfolioSummary) {
                const originalTrailingPeriodName = p.portfolioSummary.trailingPeriodName
                let trailingPeriodName = originalTrailingPeriodName
  
                if (!originalTrailingPeriodName) {
                  const startDate = p.portfolioSummary.trailingPeriodStartDate
                  const endDate = p.portfolioSummary.trailingPeriodEndDate
        
                  if (startDate && endDate) {
                    const start = parseFormattedDate(startDate)
                    const end = parseFormattedDate(endDate)
                    const diffInMonths = getDifferenceInMonths(end, start)
  
                    if (diffInMonths) {
                      if (diffInMonths === 1) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["one month"]
                      } else if (diffInMonths === 3) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["three month"]
                      } else if (diffInMonths === 6) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["six month"]
                      } else if (diffInMonths === 9) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["nine month"]
                      } else if (diffInMonths === 12) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["one year"]
                      } else if (diffInMonths === 24) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["two years"]
                      } else if (diffInMonths === 36) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["three years"]
                      } else if (diffInMonths === 48) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["four years"]
                      } else if (diffInMonths === 60) {
                        trailingPeriodName = TIME_PERIOD_PORTFOLIO["five years"]
                      }
                    }
                  }
                } else {
                  trailingPeriodName = TIME_PERIOD_PORTFOLIO[originalTrailingPeriodName.toLowerCase()] || originalTrailingPeriodName
                }
        
                return {
                  ...p,
                  portfolioSummary: {
                    ...p.portfolioSummary,
                    trailingPeriodName,
                  }
                }
              }
              return p
            } catch (error) {
              console.error('Error while mapping trailing period for portfolio.', error)
              return p
            }
          }).filter(p => !!p)
        }
      }),
      getStatsPerformance: build.query<PerformanceRawData[], any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/stats/performance?portfolioId=${portfolioId}&asOfDate=${asOfDate}`, method: 'GET' }),
        transformResponse: (response: {
          result: IPerformanceData[],
        }) => {
          return PerformanceRawDataListMapper(response?.result)
        },
        providesTags: ['StatsPerformance'],
      }),
      getStatsMarketValue: build.query<IMarketValue, any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/stats/marketValue?portfolioId=${portfolioId}&asOfDate=${asOfDate}`, method: 'GET' }),
        transformResponse: (response: {
          result: IMarketValue[],
        }) => {
          return response && response.result && response.result[0]
        },
        providesTags: ['StatsMarketValue'],
      }),
      getStatsAllocation: build.query<IAllocation[], any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/stats/allocation?portfolioId=${portfolioId}&asOfDate=${asOfDate}`, method: 'GET' }),
        transformResponse: (response: {
          result: IAllocation[]
        }) => {
          return (response?.result || []).map(a => ({
            ...a,
            Alloc: (a.Alloc || 0) * 100,
            TgtAllocLT: (a.TgtAllocLT || 0) * 100,
            TgtVarPct: (a.TgtVarPct || 0) * 100,
          }))
        },
        providesTags: ['StatsAllocation'],
      }),
      getSleevesFilter: build.query<PerformanceRawData[], any>({
        query: ({ portfolioId, asOfDate }) => ({ url: `/stats/filteredSleeves?portfolioId=${portfolioId}&asOfDate=${asOfDate}`, method: 'GET' }),
        transformResponse: (response: {
          result: IPerformanceData[],
        }) => {
          return PerformanceRawDataListMapper(response?.result)
        },
        providesTags: ['SleevesFilter'],
      }),
      clearStatsCache: build.mutation<boolean, AnalyticsCacheClearInput>({
        query: (data) => ({
          url: `/stats/cache/clear`,
          method: 'POST',
          data,
        }),
        invalidatesTags: ['StatsAllocation', 'StatsMarketValue', 'StatsPerformance', 'StatsSummary', 'StatsTopFiveHoldings', 'StatsTransaction']
      }),
    }),
  })

export const { useGetPortfolioByIdQuery, useGetPortfolioSettingsByIdQuery, useGetDisclaimersQuery, useGetBenchmarkEndNotesQuery, useGetBenchmarkEndNotesPDFDocumentMutation, useGetEndNotesOrderQuery, useGetEndNotesQuery } = potfolioService;
export const { useGetStatsTransactionQuery, useGetStatsTopFiveQuery, useGetStatsSummaryQuery, useGetStatsPerformanceQuery, useGetStatsMarketValueQuery, useGetStatsAllocationQuery, useGetSleevesFilterQuery, useClearStatsCacheMutation } = statsService;
export const { useGetAllTransactionQuery, useGetRecentTransactionByAccountQuery, useGetRecentTransactionQuery } = transactionService;

export function useFetchBenchmarkEndNotesWithDownload({ portfolioId, asOfDate }) {
  const [benchmarkEndNotesWithDownload, setBenchmarkEndNotesWithDownload] = useState<BenchmarkEndNotesWithDownload[]>([])
  const { data: benchmarkEndNotesResponse, isLoading: isLoadingEndNotes, isFetching: isFetchingEndNotes, isError: isErrorEndNotes } = useGetBenchmarkEndNotesQuery({ portfolioId, asOfDate })
  const [ download ] = useGetBenchmarkEndNotesPDFDocumentMutation()

  useEffect(() => {
    if (benchmarkEndNotesResponse?.benchmnarkEndNotes) {
      const fetchDownloads = async () => {
        const downloads = await Promise.all(
          benchmarkEndNotesResponse.benchmnarkEndNotes.map(async (endNote) => {
            const downloadBinary = await download({ portfolioId, benchMarkEndNotesId: endNote.benchMarkEndNotesId }).unwrap()
            return { ...endNote, downloadBinary }
          })
        )
        setBenchmarkEndNotesWithDownload(downloads)
      };

      fetchDownloads()
    }
  }, [benchmarkEndNotesResponse, portfolioId])

  return {
    data: benchmarkEndNotesWithDownload,
    isLoading: isLoadingEndNotes,
    isFetching: isFetchingEndNotes,
    isError: isErrorEndNotes,
  }
}

export function useGetPortfolioMarketValue({ portfolioId, asOfDate }) {
  const statMarketValue = useGetStatsMarketValueQuery({ portfolioId, asOfDate })
  const statPerformance = useGetStatsPerformanceQuery({ portfolioId, asOfDate }, { skip: !statMarketValue.isSuccess })
  const combinedData = useMemo(() => {
    if (statMarketValue.isError) {
      return {} as IMarketValue;
    }
    const sourceMarketValueData = { ...statMarketValue?.data } || {} as IMarketValue
    if (!statPerformance.isError) {
      const sourcePerformanceData = statPerformance?.data?.map(p => ({ ...p })) || []
      const performanceData = sourcePerformanceData.find(item => item.DisplayPLIType === SLEEVE_DISPLAY_TYPE && item.Lvl === 0)
      if (performanceData) {
        sourceMarketValueData.StatusAggregate = performanceData.StatusAggregate
        sourceMarketValueData.footNoteDataFlags = performanceData.footNoteDataFlags
      }
    }
    return sourceMarketValueData
  }, [statMarketValue, statPerformance])

  return {
    ...statPerformance,
    isFetching: statMarketValue.isFetching,
    isLoading: statMarketValue.isLoading,
    isError: statMarketValue.isError,
    data: combinedData,
  }
}

export function useGetPortfolioAllocation({ portfolioId, asOfDate }) {
  const statAllocation = useGetStatsAllocationQuery({ portfolioId, asOfDate })
  const statPerformance = useGetStatsPerformanceQuery({ portfolioId, asOfDate }, { skip: !statAllocation.isSuccess })
  const combinedData = useMemo(() => {
    if (statAllocation.isError) {
      return [];
    }
    const sourceAllocationData = statAllocation?.data?.map(a => ({ ...a })) || []
    if (!statPerformance.isError) {
      const sourcePerformanceData = statPerformance?.data?.map(p => ({ ...p })) || []
      sourceAllocationData.forEach(alloc => {
        const performanceData = sourcePerformanceData.find(item => item.DisplayPLIType === SLEEVE_DISPLAY_TYPE && item.id === alloc.id)
        if (performanceData) {
          alloc.StatusAggregate = performanceData.StatusAggregate
          alloc.footNoteDataFlags = performanceData.footNoteDataFlags
        }
      })
    }
    return sourceAllocationData
  }, [statAllocation, statPerformance])

  return {
    ...statPerformance,
    isFetching: statAllocation.isFetching,
    isLoading: statAllocation.isLoading,
    isError: statAllocation.isError,
    data: combinedData,
  }
}

export function useGetPortfolioTopHoldings({ portfolioId, asOfDate }) {
  const statTopHoldings = useGetStatsTopFiveQuery({ portfolioId, asOfDate })
  const statPerformance = useGetStatsPerformanceQuery({ portfolioId, asOfDate }, { skip: !statTopHoldings.isSuccess })
  const combinedData = useMemo(() => {
    if (statTopHoldings.isError) {
      return [];
    }
    const sourceHoldingsData = statTopHoldings?.data?.result?.map(a => ({ ...a })) || []
    if (!statPerformance.isError) {
      const sourcePerformanceData = statPerformance?.data?.map(p => ({ ...p })) || []
      sourceHoldingsData.forEach(holding => {
        const performanceData = sourcePerformanceData.find(item => item.DisplayPLIType === SLEEVE_DISPLAY_TYPE && item.id === holding.id)
        if (performanceData) {
          holding.StatusAggregate = performanceData.StatusAggregate
          holding.footNoteDataFlags = performanceData.footNoteDataFlags
        }
      })
    }
    return sourceHoldingsData
  }, [statTopHoldings, statPerformance])

  return {
    ...statPerformance,
    isFetching: statTopHoldings.isFetching,
    isLoading: statTopHoldings.isLoading,
    isError: statTopHoldings.isError,
    data: combinedData,
  }
}

export function useGetAccountRecentTransactions({ accountId, asOfDate, listLimit }) {
  const statsSummary = useGetStatsSummaryQuery({ accountId, asOfDate })
  const recentTransactions = useGetRecentTransactionByAccountQuery({ accountId, asOfDate, listLimit }, { skip: !statsSummary.isSuccess })
  const combinedData = useMemo(() => {
    if (statsSummary.isError || recentTransactions.isError) {
      return [];
    }
    const sourcePortfolios = statsSummary?.data || []
    const sourceTransactions = recentTransactions?.data || []
    const filteredTransactions = sourceTransactions.filter(t => sourcePortfolios.some(p => `${p?.portfolioId}` === `${t?.portfolio?.legacyId}`)) || []
    const limit = listLimit || 10
    const transactions = [] as ITrade[]
    for (let i = 0; i < limit; i++) {
      (filteredTransactions).forEach(p => {
        const transaction = p?.transactions[i]
        if (transaction) {
          transactions.push({
            ...transaction,
            ...AdaptDateAndTimeFieldsMapper<ITrade>('date')(transaction),
            portfolioId: `${p?.portfolio?.legacyId}`,
            portfolioName: p?.portfolio?.longName,
            currency: p?.currency,
          })
        }
      })
    }
    return transactions.slice(0, limit)
  }, [statsSummary, recentTransactions])

  return {
    ...recentTransactions,
    isFetching: statsSummary.isFetching || recentTransactions.isFetching,
    isLoading: statsSummary.isLoading || recentTransactions.isLoading,
    isError: statsSummary.isError || recentTransactions.isError,
    data: combinedData,
  }
}

export const getPerformanceFiscalYearEndShortMonth = (portfolioSettings: IPortfolioSettings) => {
  if (!portfolioSettings || !portfolioSettings.fiscalYearEnd) {
    return 'Dec'
  }

  return portfolioSettings?.fiscalYearEnd?.shortMonth || 'Dec'
}

export const getPerformanceFiscalYearEndField = (portfolioSettings: IPortfolioSettings) => {
  if (!portfolioSettings || !portfolioSettings.fiscalYearEnd) {
    return 'TwrFytdDec'
  }

  const fiscalYearEndMonth = `${portfolioSettings?.fiscalYearEnd?.month || 'December'}`.toLowerCase().trim();

  switch (fiscalYearEndMonth) {
    case 'january':
      return 'TwrFytdJan'
    case 'february':
      return 'TwrFytdFeb'
    case 'march':
      return 'TwrFytdMar'
    case 'april':
      return 'TwrFytdApr'
    case 'may':
      return 'TwrFytdMay'
    case 'june':
      return 'TwrFytdJun'
    case 'july':
      return 'TwrFytdJul'
    case 'august':
      return 'TwrFytdAug'
    case 'september':
      return 'TwrFytdSep'
    case 'october':
      return 'TwrFytdOct'
    case 'november':
      return 'TwrFytdNov'
    case 'december':
      return 'TwrFytdDec'
  }

  return 'TwrFytdDec'
}

export const getPortfolioName = (data: HasPortfolioName) => {
  if (data) {
    return data?.investmentPoolName || data?.shortName
  }
  return ''
}

export const getPerformanceRawValue = (data, field, from?) => {
  const value = from && from === 'dashboard' && data?.data['portfolioSummary'] ? data?.data['portfolioSummary'][field] : data[field]
  if (value && PERFORMANCE_MEDIAN_KEYS && (data?.DisplayPLIType || '').toLowerCase().indexOf('median') === -1) {
    return (parseFloat(value) * 100)
  } else if (value && (data?.DisplayPLIType || '').toLowerCase().indexOf('median') >= 0 && PERFORMANCE_MEDIAN_KEYS[1] !== data.Name && PERFORMANCE_MEDIAN_KEYS[0] !== data.Name) {
    return (parseFloat(value))
  } else if (value && (data?.DisplayPLIType || '').toLowerCase().indexOf('median') >= 0 && (PERFORMANCE_MEDIAN_KEYS[1] === data.Name || PERFORMANCE_MEDIAN_KEYS[0] === data.Name)) {
    return (parseFloat(value))
  } else {
    return value
  }
}

export const getPerformanceValue = (data, field, from?) => {
  const value = from && from === 'dashboard' && data?.data['portfolioSummary'] ? data?.data['portfolioSummary'][field] : data[field]
  if (value && PERFORMANCE_MEDIAN_KEYS && (data?.DisplayPLIType || '').toLowerCase().indexOf('median') === -1) {
    return (parseFloat(value) * 100).toFixed(1)
  } else if (value && (data?.DisplayPLIType || '').toLowerCase().indexOf('median') >= 0 && PERFORMANCE_MEDIAN_KEYS[1] !== data.Name && PERFORMANCE_MEDIAN_KEYS[0] !== data.Name) {
    return (parseFloat(value)).toFixed(1)
  } else if (value && (data?.DisplayPLIType || '').toLowerCase().indexOf('median') >= 0 && (PERFORMANCE_MEDIAN_KEYS[1] === data.Name || PERFORMANCE_MEDIAN_KEYS[0] === data.Name)) {
    return (parseFloat(value)).toFixed(0)
  } else {
    return value
  }
}

export const roundMarketValueWidgetValue = (value: number) => {
  if ((value !== null) && (value !== undefined)) {
    // applies Half Round Up strategy by default
    return Math.round(value)
  }

  return undefined
}

export const roundMarketValueWidgetValueInMillions = (value: number) => {
  if ((value !== null) && (value !== undefined)) {
    // divide source market value by 1000000
    const dividedValue = value / 1000000;

    // then round to one decimal place
    const roundedValue = Math.round(dividedValue * 10) / 10;

    return roundedValue;
  }

  return undefined
}

export const roundPerformanceWidgetValueInMillions = (value: number) => {
  if ((value !== null) && (value !== undefined)) {
    // divide source market value by 1000000
    const dividedValue = value / 1000000;

    // then round to one decimal place
    const roundedValue = Math.floor(dividedValue * 10) / 10;

    return roundedValue;
  }

  return undefined;
}

export const getPerformanceHierarchy = (
  data: PerformanceRawData[]
): PerformanceHierarchy | null => {
  const buildPerformanceHierarchy = (data: PerformanceRawData[], parentPLISk: number): PerformanceHierarchy[] => {
    return data
      .filter(item => item.ParentPLISk === parentPLISk && item.DisplayPLIType === 'Sleeve')
      .map(item => ({
        element: item,
        children: buildPerformanceHierarchy(data, item.PLISk),
      }))
  }

  // Sort the data by Lvl in ascending order
  const sortedData = [...data].sort((a, b) => a.Lvl - b.Lvl)

  // Find the root node (the one with Lvl 0)
  const rootNode = sortedData.find(item => item.Lvl === 0 && item.DisplayPLIType === SLEEVE_DISPLAY_TYPE)

  if (!rootNode) {
    // No root node found
    return null
  }

  // Build the hierarchy starting from the root node
  const hierarchy: PerformanceHierarchy = {
    element: rootNode,
    children: buildPerformanceHierarchy(sortedData, rootNode.PLISk)
  }

  return hierarchy
}

export const getGridExcelStyles = ({
  currencySymbol,
  dateFormat,
}: {
  currencySymbol: string,
  dateFormat: string,
} = {
    currencySymbol: '',
    dateFormat: '',
  }) => {
  const excelDateFormat = `${dateFormat || ''}`.replace(/M/g, 'm').replace(/Y/g, 'y').replace(/D/g, 'd')
  const excelStyles = [
    {
      id: DATE_COLUMN_CELL_CLASS,
      dataType: 'DateTime',
      numberFormat: {
        format: `${excelDateFormat};@`,
      },
      font: FONT,
    },
    {
      id: DATE_TIME_COLUMN_CELL_CLASS,
      dataType: 'DateTime',
      numberFormat: {
        format: `${excelDateFormat} HH:mm;@`,
      },
      font: FONT,
    },
    {
      id: HTML_CELL_CLASS,
      font: FONT,
    },
    {
      id: CURRENCY_COLUMN_CELL_CLASS,
      numberFormat: {
        format: currencySymbol ? currencySymbol + '#,##0.0000_);[Red](' + currencySymbol + '#,##0.0000)' : '#,##0.0000_);[Red](#,##0.0000)',
      },
      font: FONT,
    },
    {
      id: PERCENTAGE_COLUMN_CELL_CLASS,
      numberFormat: {
        format: '#,##0.00_);[Red]-#,##0.00',
      },
      font: FONT,
    },
  ] as ExcelStyle[]
  return excelStyles
}

export const getPerformanceGridProcessCellCallback = (from?: string) => {
  return (cellCallbackParams: ProcessCellForExportParams) => {
    const { value, column, node } = cellCallbackParams
    const { colDef: columnDef } = column as any
    const colDef = columnDef as ColDef
    const { cellClass } = colDef;
    const data = node && node.data
    const field = colDef && colDef.field

    if (value && cellClass) {
      const hasCellClass = (cellClass: any, expectedCellClass: string) => {
        if (Array.isArray(cellClass)) {
          return cellClass.includes(expectedCellClass)
        }
        return `${cellClass || ''}`.includes(expectedCellClass)
      }

      if (hasCellClass(cellClass, DATE_COLUMN_CELL_CLASS)) {
        return formatDate(value, DATE_COLUMN_FORMAT);
      } else if (hasCellClass(cellClass, DATE_TIME_COLUMN_CELL_CLASS)) {
        return formatDate(value, DATE_TIME_COLUMN_FORMAT);
      } else if (hasCellClass(cellClass, CURRENCY_COLUMN_CELL_CLASS)) {
        if (data && field) {
          try {
            const currencyValue = Number(data[field] || value)?.toFixed(4)
            return currencyValue
          } catch (error) {
            console.error('Error while parsing currency column value.', error)
          }
        }
      } else if (hasCellClass(cellClass, PERCENTAGE_COLUMN_CELL_CLASS)) {
        try {
          const percentageValue = getPerformanceRawValue(data, field, from)
          return percentageValue?.toFixed(6)
        } catch (error) {
          console.error('Error while parsing percentage column value.', error)
        }
      }
    }

    return value;
  }
}

export const getClientPortfolioPerformanceGridProcessCellCallback = (from?: string) => {
  return (cellCallbackParams: ProcessCellForExportParams) => {
    const { value, column, node } = cellCallbackParams
    const { colDef: columnDef } = column as any
    const colDef = columnDef as ColDef
    const { cellClass } = colDef;
    const data = node && node.data
    const field = colDef && colDef.field
    const { StatusAggregate } = data || {}
    let disName = ''

    if (value && cellClass) {
      const hasCellClass = (cellClass: any, expectedCellClass: string) => {
        if (Array.isArray(cellClass)) {
          return cellClass.includes(expectedCellClass)
        }
        return `${cellClass || ''}`.includes(expectedCellClass)
      }

      if (hasCellClass(cellClass, DATE_COLUMN_CELL_CLASS)) {
        return formatDate(value, DATE_COLUMN_FORMAT);
      } else if (hasCellClass(cellClass, DATE_TIME_COLUMN_CELL_CLASS)) {
        return formatDate(value, DATE_TIME_COLUMN_FORMAT);
      } else if (hasCellClass(cellClass, CURRENCY_COLUMN_CELL_CLASS)) {
        if (data && field) {
          try {
            const currencyValue = Number(data[field] || value)?.toFixed(4)
            return currencyValue
          } catch (error) {
            console.error('Error while parsing currency column value.', error)
          }
        }
      } else if (hasCellClass(cellClass, PERCENTAGE_COLUMN_CELL_CLASS)) {
        try {
          const percentageValue = getPerformanceRawValue(data, field, from)
          return percentageValue?.toFixed(6)
        } catch (error) {
          console.error('Error while parsing percentage column value.', error)
        }
      }
      else if (hasCellClass(cellClass, EXPORT_CELL_CLASS)) {
        if (StatusAggregate) {
          if (StatusAggregate === 'Preliminary') {
            disName = " * "
          } else if (StatusAggregate === 'PROXY') {
            disName = " + "
          }
          return `${value}${disName}`
        }
      }
    }

    return value
  }
}

export const exportGridData = ({
  gridApi,
  fileName,
  excelTabName,
  processCellCallback,
}: {
  gridApi: GridApi,
  fileName: string,
  excelTabName: string,
  processCellCallback?: (cellCallbackParams: ProcessCellForExportParams) => string,
}) => {
  gridApi.exportDataAsExcel({
    fileName,
    sheetName: excelTabName,
    processCellCallback,
  })
}