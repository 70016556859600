import { EAccountType, EUserCan, Permission } from '../types'

const allPermissions = Object.values(EUserCan).filter(Number) as Array<Permission>
const generalAccess = [EUserCan.Access_Insights, EUserCan.Access_Events, EUserCan.Access_Dashboard, EUserCan.Access_Additional_Info, EUserCan.Access_More_Links]

const CAEmployeePermissions: Array<Permission> = [...generalAccess, EUserCan.View_Optica_Links, EUserCan.View_iLevel_Links, EUserCan.View_Portfolio_Workbench_Link]
const ProdSysAdminPermissions: Array<Permission> = allPermissions
const ContentAdminsPermissions: Array<Permission> = [...CAEmployeePermissions, EUserCan.Edit_Content, EUserCan.Manage_Events]
const ITSupportPermissions: Array<Permission> = [...CAEmployeePermissions, EUserCan.Access_Clients, EUserCan.Access_Client, EUserCan.Impersonate_Users, EUserCan.Edit_Maintenance_Data, EUserCan.Access_OnboardingDashboard, EUserCan.View_Contact_TermsOfService_History]
const InvestmentPermissions: Array<Permission> = [...CAEmployeePermissions, EUserCan.Access_Clients, EUserCan.Access_Client, EUserCan.Send_Messages, EUserCan.Impersonate_Users, EUserCan.Access_OnboardingDashboard, EUserCan.Edit_Account_Settings]
const ClientSupportPermissions: Array<Permission> = [...CAEmployeePermissions, EUserCan.Access_Clients, EUserCan.Access_Client, EUserCan.Impersonate_Users, EUserCan.Send_Invites, EUserCan.Access_OnboardingDashboard, EUserCan.View_Contact_TermsOfService_History]
const ClientOnboardingPermissions: Array<Permission> = [...CAEmployeePermissions, EUserCan.Access_Clients, EUserCan.Access_Client, EUserCan.Impersonate_Users, EUserCan.Send_Invites, EUserCan.Access_OnboardingDashboard, EUserCan.Edit_OnboardingDashboard, EUserCan.Edit_Account_Settings]
const ClientContactPermissions: Array<Permission> = [...generalAccess, EUserCan.View_Optica_Links, EUserCan.View_iLevel_Links, EUserCan.View_Portfolio_Workbench_Link]
const CARetireePermissions: Array<Permission> = [...generalAccess, EUserCan.View_Media]
const ClientContactDocumentOnlyDashboardPermissions: Array<Permission> = []

export const defaultContactPermissions: Array<Permission> = [...CAEmployeePermissions]
export const defaultCAEmployeePermissions: Array<Permission> = CAEmployeePermissions
export const defaultALaCartePermissions: Array<Permission> = [EUserCan.Access_Events, EUserCan.Access_Dashboard, EUserCan.Access_Additional_Info, EUserCan.View_Optica_Links, EUserCan.View_iLevel_Links, EUserCan.View_Portfolio_Workbench_Link, EUserCan.Access_More_Links]

export const permissionGroups: Record<EAccountType, Array<Permission>> = {
  [EAccountType['SSO_ClientWebsite SysAdmin']]: ProdSysAdminPermissions,
  [EAccountType['SSO_ClientWebsite Content Admins']]: ContentAdminsPermissions,
  [EAccountType['SSO_ClientWebsite IT Support']]: ITSupportPermissions,
  [EAccountType['SSO_ClientWebsite Client Support']]: ClientSupportPermissions,
  [EAccountType['SSO_ClientWebsite Client Onboarding']]: ClientOnboardingPermissions,
  [EAccountType.Investment]: InvestmentPermissions,
  [EAccountType.ClientContact]: ClientContactPermissions,
  [EAccountType.CARetiree]: CARetireePermissions,
}

export const isClientContactDashboardOnlyPermission = (clientContactRole: string) => {
  return ['Custodian', 'Investment Committee (Restricted)', 'Auditor', 'Third Party Advisor'].includes(clientContactRole)
}

export const clientContactPermissionGroups: {
  [key: string]: Array<Permission> 
} = {
  DocumentOnlyDasboardPermission: ClientContactDocumentOnlyDashboardPermissions,
}
